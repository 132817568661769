<template>
  <section>
    <div class="content-header">
      <h2>Promoting resilience with meditation </h2>
    </div>
    <div class="content-wrapper">
      <!-- <h4>Video</h4> -->
      <p>Consider incorporating mindful breathing at the start, end, or in the middle of your lecture to model stress management and practice alongside your students.</p>
      <!-- <div class="video-player"> -->
        <!-- video element -->
        <!-- <div class="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/NpEaa2P7qZI" title="YouTube video" allowfullscreen></iframe>
        </div>
        <div class="accordion">
          <accordion
            headerText="Transcript"
            accordionID="transcript01"
          >
            <p>Descriptive transcript of video will appear here.</p>
          </accordion>
        </div>
      </div> -->
      <!-- <div class="audio-player" ref="parentDiv">
          <vue-plyr>
            <audio controls crossorigin playsinline ref="foo">
              <source
                src="@/assets/audio/personal/09.mp3"
                type="audio/mp3"
              />
            </audio>
          </vue-plyr>
          <av-line
            v-if="doRender"
            :canv-width="cW"
            :line-width="1"
            line-color="#39B54A"
            ref-link="foo"
          ></av-line>
        </div> -->
        <p>Here’s <a href="https://www.uclahealth.org/marc/mpeg/01_Breathing_Meditation.mp3" target="_blank">a guided meditation</a> from UCLA Health to help your students, and yourself, manage stress.</p>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      // GET CANVAS WIDTH DEMO
      // cW: 0,
      // doRender: false
    }
  },

  mounted () {
    // reset AvWaveform element width
    // this.cW = this.$refs.parentDiv.offsetWidth - (16 * 2)
    // // render it
    // this.doRender = true
  }
}
</script>

<style>
</style>
